<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      <template v-if="product.prices != 'undefined'">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      </template>
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <template v-if="layoutType === 'blocks' && screenWidth > 576">
            <div class="ph-order-product">
              <transition name="fade">
                <utlz-order-product
                  v-if="showOrderProductControl && product.prices.length"
                  :product="computedProduct"
                  :parentClassRef="'ref-product-list-item'"
                >
                </utlz-order-product>
              </transition>
              <!-- BESPOKE CMC-27: Added more info button -->
              <a :href="product.targetUrl" v-translation="{ code: 'button_more_info', type: 'button' }" class="button more-info-btn"></a>
              <!-- END BESPOKE CMC-27 -->

              <transition name="fade">
                <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                  <i class="fa fa-warning"></i>
                  <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                </p>
              </transition>
            </div>
          </template>

          <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control>

          <figure class="img-square prod-img">
            <a :href="product.targetUrl" class="clickthrough" :class="imageRatioClass">
              <img :ref="'productImage'" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <!-- BESPOKE CMC-27: Added div.text-container-inner -->
        <div class="text-container-inner">
          <a :href="product.targetUrl" class="clickthrough">          
            <!-- BESPOKE CMC-27: Combined product code with title -->
            <!-- <div class="product-code">
              <span class="value">{{ product.id }}</span>
            </div>
            <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2> -->
            <h2 class="title-heading">
              <span class="prod-code">{{ product.id }}</span>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <!-- BESPOKE CMC-53: Added v-html so breaks in the short description produce a line break -->
              <span v-html="product.shortDescription" class="prod-title">                
              </span>
              <!-- END BESPOKE CMC-53 -->
            </h2>
            <!-- END BESPOKE CMC-27 -->

            <!-- BESPOKE CMC-27: Added prod_desc2 -->
            <h3 class="prod-desc2">
              {{ product.customStrings.PROD_DESC2 }}
            </h3>
            <!-- END BESPOKE CMC-27 -->
          </a>

          <div class="ph-product-price" v-if="product.prices.length">
            <utlz-product-price
              :prices="product.prices[0]"
              :parentClassRef="'ref-product-list-item'"
            ></utlz-product-price>
          </div>
        
        </div>

        <utlz-product-variant
          v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant>

        <!-- BESPOKE CMC-27: Wrapped order product in template 
        since we do not use it on this position in block layout -->        
        <template v-if="layoutType === 'rows' || screenWidth < 576">
          <div class="ph-order-product">
            <transition name="fade">
              <utlz-order-product
                v-if="showOrderProductControl && product.prices.length"
                :product="computedProduct"
                :parentClassRef="'ref-product-list-item'"
              >
              </utlz-order-product>
            </transition>
            <!-- BESPOKE CMC-27: Disable out of stock message, since we show "no stock" in the stock component anyway -->
            <!-- <transition name="fade">
              <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                <i class="fa fa-warning"></i>
                <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
              </p>
            </transition> -->
            <!-- END BESPOKE CMC-27 -->
          </div>
        </template>
        <!-- END BESPOKE CMC-27 -->

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->

        <!-- BESPOKE CMC-27: Only show stock in the rows overview and mobile -->
        <template v-if="layoutType === 'rows' || screenWidth < 576">
          <utlz-product-stock
            v-if="!product.variantKey || groupProductsList == 0"
            :stock="product.stock"
            :stockTotal="product.stock"
          ></utlz-product-stock>
        </template>
        <!-- END BESPOKE CMC-27 -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      isSalesAction: this.product.prices[0],
      activeProductVariantId: null,
      imageRatioClass: ''
    };
  },
  computed: {
    ...mapGetters('elastic', ['layoutType']),
    // BESPOKE CMC-7
    // Added screenWidth getter
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth']),
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    }
  },
  mounted () {
    this.$refs.productImage.onload = () => {
      let ratioClass = 'square';
      const width = window.$(this.$refs['productImage']).context.naturalWidth;
      const height = window.$(this.$refs['productImage']).context.naturalHeight;
      const ratio = width / height;
      if (ratio < 1) {
        ratioClass = 'portrait';
      } else if (ratio > 1) {
        ratioClass = 'landscape';
      }
      this.imageRatioClass = ratioClass;
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    }
  }
};
</script>

<style>
</style>
