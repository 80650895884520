<template>
  <div class="cmp-order-product normalize-headers" :class="parentClassRef">

    <utlz-order-normal
      v-if="orderType === 'normal'"
      @orderNormal="orderProductNormal($event)"
    ></utlz-order-normal>

    <utlz-order-saleunit
      v-if="orderType === 'saleUnit'"
      :saleUnit="product.saleUnit"
    ></utlz-order-saleunit>

    <utlz-order-units
      v-if="orderType === 'units' && product.stock"
      :units="product.units"
      :stockTotal="product.stock.stockTotal"
      :selectedUnitCode="selectedUnitCode"
      @unitsChanged="unitsChanged($event)"
    ></utlz-order-units>

    <utlz-order-grouped v-if="orderType === 'grouped'" :product="product"></utlz-order-grouped>

    <utlz-order-volume-discount
      v-if="orderType === 'volumeDiscount'"
      :volumeDiscount="product.prices"
      @volumeDiscountChanged="volumeDiscountChanged($event)">
    </utlz-order-volume-discount>

    <input v-if="useOrderComment" type="text" :value="orderCommentField" ref="orderComment" class="order-comment">

    <div class="order-product-wrapper flex-row-nowrap" :class="{ 'disabled': stockLimit && !isValidQuantity }">      
      
      <!-- BESPOKE EDEX-46: Added totals to the quantity buttons and wraed it in quantity-btns-container -->
      <div class="quantity-btns-container">
        <utlz-quantity-buttons
          :initialQuantity="quantity"
          :quantityFactor="quantityFactor"
          @quantityChanged="quantityChanged($event)">
        </utlz-quantity-buttons>

        <div class="quantity-message" v-if="product.saleUnit > 0">
          <span>{{ quantity }}</span> x <span>{{ product.saleUnit }}</span> = <span>{{ quantity * product.saleUnit }}</span>
        </div>
        <!-- END BESPOKE EDEX-46 -->
      </div>

      <a 
        v-translation="{ code: 'button_order', type: 'button' }" 
        v-if="useOrderMode" @click="orderProduct" class="button add-to-cart-btn small">
        <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
      </a>

      <!-- Displays stocklimit exceeded tooltip -->
      <transition name="fade">
        <template v-if="stockLimit && !isValidQuantity">
          <div class="utlz-tooltip stock-limit danger">
            <p v-translation="{ type: 'label', code: 'label_exceeds_stock_limit' }"></p>
          </div>
        </template>
      </transition>

    </div>

  </div>
</template>

<script>
import OrderNormal from 'src/components/webshop/order-product/blocks/OrderNormal.vue';
import OrderSaleUnit from 'src/components/webshop/order-product/blocks/OrderSaleUnit.vue';
import OrderUnits from 'src/components/webshop/order-product/blocks/OrderUnits.vue';
import OrderGrouped from 'src/components/webshop/order-product/blocks/OrderGrouped.vue';
import OrderVolumeDiscount from 'src/components/webshop/order-product/blocks/OrderVolumeDiscount.vue';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import { Product } from 'src/models/product.model';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'utlz-order-normal': OrderNormal,
    'utlz-order-saleunit': OrderSaleUnit,
    'utlz-order-units': OrderUnits,
    'utlz-order-grouped': OrderGrouped,
    'utlz-order-volume-discount': OrderVolumeDiscount,
    'utlz-quantity-buttons': QuantityButtons
  },
  props: {
    product: { type: Product, required: true },
    initialQuantity: { type: Number, required: false, default: 1 },
    useOrderComment: { type: Boolean, required: false, default: false },
    orderComment: { type: String, required: false },
    useOrderMode: { type: Boolean, default: true },
    parentClassRef: { type: String, default: '', required: false }
  },
  data () {
    return {
      quantity: this.initialQuantity,
      quantityFactor: 1,
      orderType: 'normal',
      selectedUnitCode: ''
    };
  },
  computed: {
    ...mapGetters(['stockLimit', 'showStock']),
    orderCommentField () {
      return this.orderComment;
    },
    isValidQuantity () {
      if (this.stockLimit) {
        if (this.quantity <= this.product.stock.stockTotal) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    product () {
      this.setOrderType();
      this.setOrderQuantities();
    }
  },
  methods: {
    setOrderType () {
      let type = 'normal';
      if (this.product.units) {
        type = 'units';
      } else if (this.product.saleUnit > 1) {
        type = 'saleUnit';
      }
      this.orderType = type;
    },
    setOrderQuantities () {
      switch (this.orderType) {
        case 'normal':
          this.quantityFactor = 1;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'saleUnit':
          this.quantityFactor = this.product.saleUnit;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'volumeDiscount':
          this.quantityFactor = this.product.prices[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'units':
          this.selectedUnitCode = this.product.units[0].code;
          this.quantityFactor = this.product.units[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
      }
    },
    orderProduct () {
      let payload = this.getOrderPayload();
      this.addToCart(payload)
        .then(res => {
          this.quantity = this.quantityFactor;
        });
    },
    getOrderPayload () {
      let quantity = this.quantity;
      if (this.orderType === 'units') {
        quantity = this.quantity / this.quantityFactor;
      } else if (this.orderType === 'volumeDiscount' && this.product.saleUnit > 1) {
        quantity = this.quantity / this.product.saleUnit;
      } else if (this.orderType === 'saleUnit') {
        quantity = this.quantity / this.product.saleUnit;
      }
      return {
        prod_code: this.product.id,
        ord_qty: quantity,
        unit_code: this.selectedUnitCode,
        prod_comment: this.useOrderComment ? this.$refs.orderComment.value : ''
      }
    },
    quantityChanged ({ quantity }) {
      this.quantity = quantity;
    },
    unitsChanged ({ quantity, unitCode }) {
      this.selectedUnitCode = unitCode;
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    volumeDiscountChanged (quantity) {
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    ...mapActions('shoppingCart', ['addToCart'])
  },
  created () {
    this.setOrderType();
    this.setOrderQuantities();
  }
};
</script>

<style>
</style>
